/**
 * This flight component relies on jquery ui calendars.
 *
 * This expects a dom element such as:
 *
 *   <div class="do_dates">
 *      <div class="input-container">
 *          <label>
 *              <i class="icon-icon_calendar"></i>
 *              <input
 *                  type="text"
 *                  name="arrival_date"
 *                  class="searchform__input"
 *                  placeholder="Date"
 *                  readonly="readonly"
 *                  autocomplete="off"
 *                  autocorrect="off" value=""/>
 *          </label>
 *      </div>
 *
 *      <div class="input-container">
 *          <label>
 *              <i class="icon-icon_calendar"></i>
 *              <input
 *                  type="text"
 *                  name="departure_date"
 *                  class="searchform__input"
 *                  placeholder="Date"
 *                  readonly="readonly"
 *                  autocomplete="off"
 *                  autocorrect="off" value=""/>
 *          </label>
 *      </div>
 *  </div>
 *
 * The important take away is that the there is a container that has two child input fields. It
 * will be assumed that the first field is the departure calendar and and the second input will be
 * the return date.
 *
 * When a date is selected a data attribute 'value' is added to the input that holds the current date in the
 * the following format: YYYY-MM-DD
 *
 * Listens To:
 * ===========
 *
 * @event data:searchForm:calendar:date1:changed => The departure/checkin date has changed.
 *  @param {String} date - the new date for the calendar to display format is YYYY-MM-DD.
 *
 *
 * @event data:searchForm:calendar:date2:changed => The return/checkout date has changed.
 *  @param {String} date - the new date for the calendar to display format is YYYY-MM-DD.
 *
 * Triggers:
 * =========
 * @event ui:calendar:dates:changed => The departure/checkin date has changed.
 *  @param {Object} data - the new dates for the calendar to display format is YYYY-MM-DD.
 *      @param {String} date1
 *      @param {String} date2
 *
 */
var defineComponent = require("flight/lib/component");
var moment = require("moment");
var $j = require("jquery");
var _ = require("underscore");
var SiteIdUtils = require("src/desktop/utils/siteId");

var component = defineComponent(calendar);

function calendar () {
    this.attributes({
        /**
         * EVENTS
         * =======
         */

        /**
         * @event data:searchForm:calendar:date1:changed => The departure/checkin date has changed.
         *  @param {String} date - the new date for the calendar to display format is YYYY-MM-DD.
         */
        EV_NEW_DATE_1: "data:searchForm:calendar:date1:changed",

        /**
         * @event data:searchForm:calendar:date2:changed => The return/checkout date has changed.
         *  @param {String} date - the new date for the calendar to display format is YYYY-MM-DD.
         */
        EV_NEW_DATE_2: "data:searchForm:calendar:date2:changed",

        /**
         * @event ui:calendar:dates:changed => The departure/checkin date has changed.
         *  @param {Object} data - the new dates for the calendar to display format is YYYY-MM-DD.
         *      @param {String} date1
         *      @param {String} date2
         */
        EV_CHANGED_DATES: "ui:calendar:dates:changed",

        /**
         * CONSTANTS
         * =========
         */

        /**
         * The incoming and outgoing date format
         * DO NOT CHANGE THIS
         */
        DATE_FORMAT: "YYYY-MM-DD",

        /**
         * INTERNAL STATE
         * ==============
         */


        /**
         * The max days out we allow for a search
         */
        maxDays: 300,

        /**
         * The earliest possible checkin date
         */
        date1MinMoment: moment(),

        /**
         * The current checkin date
         */
        date1Moment: moment(),

        /**
         * The max stay duration
         */
        checkoutMaxDays: 28,

        /**
         * The current checkout date
         */
        date2Moment: moment().add(7, "days"),

        /**
         * The number of the months each picker will show.
         */
        numOfMonths: 2,

        /**
         * Controls the alignment of the datepicker(s). Default is to align
         * with the left of the input, setting this attribute to true will
         * align with the right edge
         */
        alignRight: false,

        /**
         * The format to display the selected date in.
         */
        displayDateFormat: SiteIdUtils.getDisplayDateFormat(SiteIdUtils.defaultSiteId)
    });

    /**
     * Silently updates date1.
     * @param {String} dateString the new date YYYY-MM-DD
     */
     this.updateDate1External = function (event, dateString) {
         var date = moment(dateString, this.attr.DATE_FORMAT);
         this.updateDate1Moment(date, true);

     };

    /**
     * Silently updates date2.
     * @param {String} dateString the new date YYYY-MM-DD
     */
    this.updateDate2External = function (event, dateString) {
        var date = moment(dateString, this.attr.DATE_FORMAT);
        this.updateDate2Moment(date, true);
    };

    /**
     * This finds the correct calendar instances and then updates the dates that are set.
     * @param {moment} date1Moment
     * @param {moment} date2Moment
     */
    this.updateCalendars = function (date1Moment, date2Moment) {
        var date1Input = this.$node.find("input")[0],
            date2Input = this.$node.find("input")[1];

        $j(date1Input).datepicker("option", this.getDate1DatepickerOptions()).datepicker("setDate", date1Moment.clone().toDate());
        $j(date2Input).datepicker("option", this.getDate2DatepickerOptions()).datepicker("setDate", date2Moment.clone().toDate());
        this.updateDataValue(date1Moment, date2Moment);
    };

    /**
     * Updates the data attributes on the input fields to match the new dates.
     * @param date1Moment
     * @param date2Moment
     */
    this.updateDataValue = function (date1Moment, date2Moment) {
        var date1Input = this.$node.find("input")[0],
            date2Input = this.$node.find("input")[1];

        //Because the date picker is on these imputs the .data() method seems to be losing the reference out side of this file.
        var date1String = date1Moment.clone().format(this.attr.DATE_FORMAT);
        var date2String = date2Moment.clone().format(this.attr.DATE_FORMAT);
        //TODO: I have to set both the attr and the data to avoid AWD-871.... Get the bottom this someday...
        $j(date1Input).attr("data-date", date1String);
        $j(date2Input).attr("data-date", date2String);
        $j(date1Input).data("date", date1String);
        $j(date2Input).data("date", date2String);
    };


    /**
     * Event handler for updating the display of a day in the calendar. This does the following:
     *  - Highlight the checkin and checkout date with the active state.
     *  - Highlight the dates in between the checkin and checkout date with the hover state.
     *
     * @param {Date} date The date to show.
     * @return {Array} A 3-item array where the 1st item is true/false indicating whether or not the
     *  date is selectable, the second item is the CSS class name to add to the date's cell or an
     *  empty string for the default presentation, and lastly an optional popup tooltip for this
     *  date as the 3rd item.
     */
    this.onBeforeDatepickerShowDay = function(date) {
        /* {moment} */
        var theMoment = moment(date);
        var checkinMoment = this.attr.date1Moment;
        var checkoutMoment = this.attr.date2Moment;

        if (theMoment.isSame(checkinMoment, "day") || theMoment.isSame(checkoutMoment, "day")) {
            return [true, "ui-state-active", null];
        } else if (theMoment.isAfter(checkinMoment, "day") && theMoment.isBefore(checkoutMoment, "day")) {
            return [true, "ui-state-hover", null];
        } else {
            return [true, "", null];
        }
    };

    /**
     * Appropriately align the datepicker boxes
     * @param input
     * @param inst
     */
    this.onBeforeDatepickerShow = function(input, inst) {
        if (this.attr.alignRight) {
            var dp = $j(inst.dpDiv);
            var offset = $j(input).outerWidth(false) - dp.outerWidth(false);
            dp.css("margin-left", offset);
        }
    };

    /**
     * Event handler for when the dates are selected. This does the following:
     *  - Figures out if the given instance is of a checkin or checkout input and update the date
     *      appropriately.
     *  - Update the options of all the date picker instances based on the new data.
     *  - Sets the updated checkin and checkout date for all the date picker instances.
     *
     * @param {string} dateString The selected date as a string as it is displayed in the input.
     * @param {Object} instance The date picker instance that triggered this event.
     */
    this.onDateSelected = function(dateString, instance) {
        /* {jQuery} */
        var input = instance.input;
        /* {moment} */
        var selectedMoment = moment({
            year: instance.selectedYear,
            month: instance.selectedMonth,
            day: instance.selectedDay
        });

        // Update the correct underlying date. This assumes that the first field is date1
        if ($j(this.$node.find("input")[0]).filter(input).length) {
            this.updateDate1Moment(selectedMoment);
        } else {
            this.updateDate2Moment(selectedMoment);
        }
    };


    /**
     * Update the checkin date. The following will be enforced:
     *  - If the given checkin date is before today, it will be made today.
     *  - If the given checkin date is the same or after the checkout date, the checkout date will
     *      be made one day after the given checkin date.
     *  - If the checkout date is more than the checkout max days from the checkin date, the
     *      checkout will be made the last possible date.
     *
     * @param {moment} checkinMoment The checkin date.
     * @return {this}
     */
    this.updateDate1Moment = function(checkinMoment, silent) {
        /* {moment} */
        var checkoutMoment = this.attr.date2Moment;
        var todayMoment = moment();
        var lastPossibleCheckoutMoment;

        if (checkinMoment.isBefore(todayMoment, "day")) {
            checkinMoment = todayMoment;
        }

        this.attr.date1Moment = checkinMoment;
        lastPossibleCheckoutMoment = checkinMoment.clone().add(this.attr.checkoutMaxDays, "day");

        if (checkinMoment.isSame(checkoutMoment, "day") || checkinMoment.isAfter(checkoutMoment, "day")) {
            this.attr.date2Moment = checkinMoment.clone().add(1, "day");
        } else if (checkoutMoment.isAfter(lastPossibleCheckoutMoment, "day")) {
            this.attr.date2Moment = lastPossibleCheckoutMoment;
        }

        if (!silent) {
            this.$node.trigger(this.attr.EV_CHANGED_DATES, {
                date1: this.attr.date1Moment.format(this.attr.DATE_FORMAT),
                date2: this.attr.date2Moment.format(this.attr.DATE_FORMAT)
            });
        }

        this.updateCalendars(this.attr.date1Moment, this.attr.date2Moment);
    };

    /**
     * Update the checkout date. The following will be enforced:
     *  - It will only update if the given checkout date is after the checkin date.
     *
     * @param {moment} checkoutMoment The checkout date.
     * @param {boolean} silent Should this suppress the date changed events?
     * @return {this}
     */
    this.updateDate2Moment = function(checkoutMoment, silent) {
        /* {moment} */
        var checkinMoment = this.attr.date1Moment;

        if (checkoutMoment.isAfter(checkinMoment, "day")) {
            this.attr.date2Moment = checkoutMoment;

            if (!silent) {
                this.$node.trigger(this.attr.EV_CHANGED_DATES, {
                    date1: this.attr.date1Moment.format(this.attr.DATE_FORMAT),
                    date2: this.attr.date2Moment.format(this.attr.DATE_FORMAT)
                });
            }
        }

        this.updateCalendars(this.attr.date1Moment, this.attr.date2Moment);
    };

    /**
     * Get the datepicker options for the checkin inputs.
     *
     * @return {Object}
     */
    this.getDate1DatepickerOptions = function() {
        /* {moment} */
        var checkinMinMoment = this.attr.date1MinMoment;
        /* {int} */
        var checkinMaxDays = this.attr.maxDays;
        var numOfMonths = this.attr.numOfMonths;

        return {
            maxDate: checkinMinMoment.clone().add(checkinMaxDays, "days").toDate(),
            minDate: checkinMinMoment.clone().toDate(),
            numberOfMonths: numOfMonths,
            showOptions: { direction: "down" },
            dateFormat: this.attr.displayDateFormat
        };
    };

    /**
     * Get the datepicker options for the checkout inputs.
     *
     * @return {Object}
     */
    this.getDate2DatepickerOptions = function() {
        /* {moment} */
        var checkinMoment = this.attr.date1Moment;
        /* {int} */
        var checkoutMaxDays = this.attr.checkoutMaxDays;
        var numOfMonths = this.attr.numOfMonths;

        return {
            maxDate: checkinMoment.clone().add(checkoutMaxDays, "days").toDate(),
            minDate: checkinMoment.clone().toDate(),
            numberOfMonths: numOfMonths,
            showOptions: { direction: "down" },
            dateFormat: this.attr.displayDateFormat
        };
    };


    /**
     * This function initializes the jquery calendars and sets up the default dates.
     */
    this.setup = function (){
        var date1Options = this.getDate1DatepickerOptions(),
            date2Options = this.getDate2DatepickerOptions(),
            date1Input = this.$node.find("input")[0],
            date2Input = this.$node.find("input")[1];


        /* {DatepickerController}: {Object} */
        _.each([date1Options, date2Options], function(options) {
            options.onSelect = _.bind(this.onDateSelected, this);
            options.beforeShowDay = _.bind(this.onBeforeDatepickerShowDay, this);
            options.beforeShow = _.bind(this.onBeforeDatepickerShow, this);
        }, this);

        // Create the date pickers and set the date for them
        $j(date1Input).datepicker(date1Options)
            .datepicker("setDate", this.attr.date1Moment.clone().toDate());
        $j(date2Input).datepicker(date2Options)
            .datepicker("setDate", this.attr.date2Moment.clone().toDate());

        this.updateDataValue(this.attr.date1Moment, this.attr.date2Moment);
    };

    this.after("initialize", function (){
        this.setup();
        this.on(this.attr.EV_NEW_DATE_1, this.updateDate1External);
        this.on(this.attr.EV_NEW_DATE_2, this.updateDate2External);
    });
}

module.exports = component;