var mixin = function () {

    this.attributes({
        /**
         * @event ui:radio:changed  a radio button on the form changed.
         */
        EV_RADIOBUTTON_CHANGED: "ui:radio:changed",

        oneway: false
    });

    this.onRadioChange = function (event, data) {
        if (data && data.name == "flightType") {
            this.attr.oneway = data.value === "oneway";
            this.setup(this.attr.oneway);
        }
    };

    this.after("initialize", function () {
        this.on("body", this.attr.EV_RADIOBUTTON_CHANGED, this.onRadioChange.bind(this));
    });
};

module.exports = mixin;