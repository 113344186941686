var $j = require("jquery");
var stm = require("stm");

var AutoSuggestData = require("flight-autosuggest/lib/data/autosuggest");
var AutoSuggestUI = require("flight-autosuggest/lib/ui/desktop-air-autosuggest-with-parent-id");
var AirExactDateSearchFormSingleCalendar = require("src/desktop/modules/flight-search-form/lib/ui/air-exact-date-search-selective-redirect");
var CalendarUI = require("src/desktop/modules/flight-calendar/lib/ui/calendar");
var CalendarSingleInputFlightUI = require("src/desktop/modules/flight-calendar/lib/ui/single-input-calendar-flight");

var SiteIdUtils = require("src/desktop/utils/siteId");

var SelectUI = require("src/desktop/modules/flight-search-form/lib/ui/select");
var RadioUI = require("src/desktop/modules/flight-search-form/lib/ui/radio");

var WatchRouteUI = require("src/desktop/modules/flight-watch-fare-alerts/base/watch-route");
var UserSubModalEntryGateUI = require("src/desktop/modules/user-subscription-modal/lib/ui/user-sub-modal-entry-gate");

// ---------------------------------------------------------------------------------------------- //

var PageData = stm.pageData.hwd;

$j(function() {

    AutoSuggestData.attachTo("body", {endPoint: PageData.endPointContext});
    AutoSuggestUI.attachTo(".do_auto_suggest", {
        modalRemovalTimeout: 0,
        pairedFieldsSelector:".fare_search_locations"
    });

    var isMobile = stm.pageData.hwd.device.mobile;
    var numMonths = isMobile ? 1 : 2;
    CalendarUI.attachTo(".do_dates", {
        maxDays: 330,
        checkoutMaxDays: 330,
        numOfMonths: numMonths,
        displayDateFormat: SiteIdUtils.getDisplayDateFormat(PageData.siteId)
    });

    SelectUI.attachTo(".do_select");
    RadioUI.attachTo(".do_radio");

    AirExactDateSearchFormSingleCalendar.attachTo(".find-fare-form", {_id: "do_exact_date_air_search"});

    CalendarSingleInputFlightUI.attachTo(".date_container", {
        checkoutMaxDays: 330,
        dateRangeFormat: SiteIdUtils.getSingleDisplayDateFormat(PageData.siteId)
    });

    WatchRouteUI.attachTo("body", {
        searchData: stm.externalSearchData,
        _id: "watch-route-sub-layer",
        _watchRouteSelector: ".route_watch_callout_cta",
        _submitAlertElement: ".do_submit_watch_cta"
    });

    UserSubModalEntryGateUI.attachTo(".do_subscribe_users");
});