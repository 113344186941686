/**
 * This component detects changes on a group of radio buttons and emits a change event. This component supports
 * the programatic update of radio buttons w/o emiting the change event.
 *
 * EXAMPLE HTML
 * ============
 *  <div class="row do_radio">
 *
 *      <input type="radio" name="flightType" checked="checked" th:value="${T(com.smartertravel.awd.model.FlightSearchType).ROUND_TRIP.id()}"/>
 *      <label class="searchform__label" th:text="#{desktop.search.form.round.trip}"></label>
 *
 *      <input type="radio" name="flightType"  th:value="${T(com.smartertravel.awd.model.FlightSearchType).ONE_WAY.id()}"/>
 *      <label class="searchform__label" th:text="#{desktop.search.form.one.way}"></label>
 *
 *  </div>
 *
 *
 * LISTENS TO
 * ==========
 *
 * @event ui:searchForm:radio:changed there has been an external change to the radio button that must be reflected
 *  @param {Object} data
 *      @param {String} name the name of the radio that the change effects. If the name does not match this component does nothing
 *                          will occur
 *      @param {String} value the value that is now selected.
 * This event will cause the underlying radio buttons to update silently.
 *
 * TRIGGERS
 * ========
 *
 * @event ui:radio:changed the component has detected a change in the radio button
 *  @param {Object} data
 *      @param {String} name the components radio name
 *      @param {String} value the selected value
 *
 */
var defineComponent = require("flight/lib/component");
var component = defineComponent(radio);

function radio() {
    this.attributes({

        /**
         * @event ui:searchForm:radio:changed there has been an external change to the radio button that must be reflected
         *  @param {Object} data
         *      @param {String} name the name of the radio that the change effects. If the name does not match this component does nothing
         *                          will occur
         *      @param {String} value the value that is now selected.
         * This event will cause the underlying radio buttons to update silently.
         */
        EV_EXTERNAL_RADIO_CHANGE: "ui:searchForm:radio:changed",

        /**
         * @event ui:radio:changed the component has detected a change in the radio button
         *  @param {Object} data
         *      @param {String} name the components radio name
         *      @param {String} value the selected value
         */
        EV_RADIO_CHANGE: "ui:radio:changed",

        /**
         * The name of the radio group
         */
        name: ""
    });

    this.enableDomListeners = function () {
        this.on(this.$node.find("input:radio"), "change", this.onChecked);
    };

    this.disableDomListeners = function () {
        this.off(this.$node.find("input:radio"), "change", this.onChecked);
    };

    this.externalChange = function (ev, data) {
        if (data.name !== this.attr.name) {
            return;
        }

        this.disableDomListeners();

        var clickedInputSelector = "[name=" + this.attr.name + "][value=" + data.value + "]";
        this.$node.find("input:not(" + clickedInputSelector + ")").prop("checked", false);
        this.$node.find("input" + clickedInputSelector).prop("checked", true);

        this.enableDomListeners();

    };

    /**
     * This finds the checked radio button and emits and event
     */
    this.onChecked = function () {
        var element = this.$node.find(":checked");
        var data = {
            name: this.attr.name,
            value: element.val()
        };

        var label = this.$node.find("label[for="+element.attr("id")+"]");
        if (label.length > 0) {
            data.label = label.text();
        }
        this.trigger(this.attr.EV_RADIO_CHANGE, data);
    };

    this.after("initialize", function () {
        this.attr.name = this.$node.find("input:radio").attr("name");
        this.enableDomListeners();
        this.on(this.attr.EV_EXTERNAL_RADIO_CHANGE, this.externalChange);
    });
}


module.exports = component;