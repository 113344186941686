/**
 * This component detects changes on a select box and emits a change event. This component supports
 * the programatic update of a select box w/o emiting the change event.
 *
 * EXAMPLE HTML
 * ============
 *  <select name="nameTravelers" class="row do_select">
 *      <option value="1"></option>
 *      <option value="2"></option>
 *  </select>
 *
 *
 * LISTENS TO
 * ==========
 *
 * @event ui:searchForm:select:changed there has been an external change to the select box that must be reflected
 *  @param {Object} data
 *      @param {String} name the name of the select that the change effects. If the name does not match this component does nothing
 *                          will occur
 *      @param {String} value the value that is now selected.
 * This event will cause the underlying select box to update silently.
 *
 * TRIGGERS
 * ========
 *
 * @event ui:select:changed the component has detected a change in the select box
 *  @param {Object} data
 *      @param {String} name the components select name
 *      @param {String} value the selected value
 *
 */
var defineComponent = require("flight/lib/component");
var component = defineComponent(select);

function select() {
    this.attributes({

        /**
         * @event ui:searchForm:select:changed there has been an external change to the select box that must be reflected
         *  @param {Object} data
         *      @param {String} name the name of the select that the change effects. If the name does not match this component does nothing
         *                          will occur
         *      @param {String} value the value that is now selected.
         * This event will cause the underlying select box to update silently.
         */
        EV_EXTERNAL_SELECT_CHANGE: "ui:searchForm:select:changed",

        /**
         * @event ui:select:changed the component has detected a change in the select box
         *  @param {Object} data
         *      @param {String} name the components select name
         *      @param {String} value the selected value
         */
        EV_SELECT_CHANGE: "ui:select:changed",

        /**
         * The name of the select
         */
        name: ""
    });

    this.enableDomListeners = function () {
        this.on("change", this.onChange);
    };

    this.disableDomListeners = function () {
        this.off("change", this.onChange);
    };

    this.externalChange = function (ev, data) {
        if (data.name !== this.attr.name) {
            return;
        }

        this.disableDomListeners();
        this.$node.val(data.value);
        this.enableDomListeners();

    };

    /**
     * This finds the checked radio button and emits and event
     */
    this.onChange = function () {
        this.trigger(this.attr.EV_SELECT_CHANGE, {
            name: this.attr.name,
            value: this.$node.val()
        });
    };

    this.after("initialize", function () {
        this.attr.name = this.$node.attr("name");
        this.enableDomListeners();
        this.on(this.attr.EV_EXTERNAL_SELECT_CHANGE, this.externalChange);
    });
}


module.exports = component;