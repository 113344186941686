var airSingleCalendarSearch = require("src/desktop/modules/flight-search-form/lib/ui/air-exact-date-search-single-calendar");
var $ = require("jquery");

var component = airSingleCalendarSearch.mixin(function () {
    this.attributes({
        submitNoRedirectSelector: ".do_submit",
        submitWithRedirectSelector: ".do_submit_redirect",
        submitSelector: "", // otherwise the base air-exact-date-sub-search-form will double-bind click listeners
        _date1Selector: ".do_dates .input-container .date1",
        _date2Selector: ".do_dates .input-container .date2",
        emailCheckbox: ".sub-widget-label .sub-widget__checkbox"
    });

    this.submit = function (event) {
        event.preventDefault();
        this.clearErrors();
        var formData = this.getData();

        if (!this.validateData(formData)) {
            return;
        }

        if (this.select("emailCheckbox").attr("checked")) {
            this.doSub(formData);
        }

        return formData;
    };

    this.submitNoRedirect = function (event) {
        var formData = this.submit(event);
        // open a new tab having tab-browsing / mobile lineup
        this.openTabBrowsing(formData);

        $("body").trigger("ui:openTabBrowsing");
    };

    this.submitRedirect = function (event) {
        var formData = this.submit(event);
        // open a new tab having tab-browsing / mobile lineup
        this.openTabBrowsing(formData);
        // redirect to fares-from page
        this.openFromLink(formData);
    };

    this.after("initialize", function () {
        this.on(this.select("submitWithRedirectSelector"), "click", this.submitRedirect);
        this.on(this.select("submitNoRedirectSelector"), "click", this.submitNoRedirect);
    });

});

module.exports = component;