var defineComponent = require("flight/lib/component");
var withSubs = require("src/desktop/modules/flight-subs/lib/mixins/with-subs");
var withCookies = require("flight-common-mixins/lib/with-cookie-support");
var $j = require("jquery");

/**
 * Fare / route watch UI
 */
var WatchRouteUI = defineComponent(withSubs, withCookies, moreLink);

/**
 * Module function
 */
function moreLink() {

    this.attributes({
        _id: null,
        _cookieName: "u",

        /**
         * selector class for watch link
         * @type {String}
         */
        _watchRouteSelector: ".route_watch_callout",

        /**
         * display dropdown for route watch entry/confirmation
         * @type {String}
         */
        _watchDropdownBox: ".route_watch_display",

        /**
         * container class for "more" link & ellipsis
         * @type {String}
         */
        _closeSelector: ".close_watch_dropdown",

        /**
         * sub entry input section
         * @type {String}
         */
        _watchEntrySection: ".watch_email_entry",

        /**
         * sub confirmation display section
         * @type {String}
         */
        _watchConfirmationSection: ".watch_email_confirmation",

        /**
         * dropdown email input element
         * @type {String}
         */
        _emailEntryElement: ".watch_dropdown__email",

        /**
         * container class for email input
         * @type {String}
         */
        _emailContainerElement: ".watch_dropdown__email_container",

        /**
         * submit element for watch alert
         * @type {String}
         */
        _submitAlertElement: ".do_submit_watch",

        /**
         * container for route watch section
         * @type {String}
         */
        _routeWatchContainer: ".route_watch_container",

        /**
         * Time to wait (in milliseconds) before confirmation window is closed after successful sub
         * @type {int}
         */
        successCloseDelayMs: 3000,

        /**
         * @type {Object} searchData
         *  @type {Number} location2TaId the ta id for the geo
         *  @type {String} location2CityName the name of the city.
         *  @type {Object} searchDate1
         *      @type {String} date YYYY-MM-DD
         *  @type {Object} searchDate2
         *      @type {String} date YYYY-MM-DD
         *
         * @see flight-search-data module for more type details
         */
        searchData: null

    });

    /**
     * This checks if the cookie that contains the user's tracking code is present. It does not check if the tracking code
     * contained in the cookie is valid.
     * @return {Boolean} true if the cookie specified by this.attr._cookieName is present and not empty
     */
    this.hasTrackingCode = function () {
        var cookie = this.getCookie(this.attr._cookieName);
        return cookie !== null && cookie.replace(/"/g,"") !== "";
    };

    this.showWatchDropdown = function() {
        if (this.hasTrackingCode()) {
            this.select("_watchEntrySection").hide();
            this.submitAlert("", true);
        }

        this.select("_watchDropdownBox").show();
    };

    this.hideWatchDropdown = function() {
        this.select("_watchDropdownBox").hide();
    };

    this.submitAlert = function(event, isCookied) {
        if (event) {
            event.preventDefault();
        }

        this.clearErrors();

        var $target = $j(event.target);
        var $parent = $target.parents(this.attr._routeWatchContainer);
        var $emailInput = $parent.find(this.attr._emailEntryElement);
        var email = $emailInput.val();

        if (email || isCookied) {
            this.attr.subData.email = email;
            this.subMultiple(this.attr.subData);
        } else {
            this.emailError();
        }
    };

    this.emailError = function () {
        this.select("_emailContainerElement").addClass("error");

        var $input = this.select("_emailEntryElement");
        var self = this;
        var handler = function (event) {
            self.clearErrors();
            $input.off("focus", handler);
        };
        $input.on("focus", handler);
    };

    this.clearErrors = function () {
        this.select("_emailContainerElement").removeClass("error");
    };

    this.onSuccessfulSub = function () {
        this.select("_watchEntrySection").hide();
        this.select("_watchConfirmationSection").show();

        var self = this;
        setTimeout(function() {
            self.select("_watchDropdownBox").hide();
            self.select("_watchRouteSelector").hide();
        }, this.attr.successCloseDelayMs);
    };

    /**
     * We only can do something if the sub fails with a email related error. So if the error code does not represent a
     * bad email we will fail silently.
     *
     * @override with-subs
     * @param {Object} data
     *  @param {Boolean} success
     *  @param {String} msg
     *  @param {Number} code
     */
    this.handleBulkSubJsonResponse = function(data) {
        if (data.success || data.code != this.attr.INVALID_EMAIL_CODE) {
            this.onSuccessfulSub();
        } else {
            this.select("_watchEntrySection").show();
            this.emailError();
        }
    };

    this.buildSubData = function() {
        this.attr.subData = {
            id: this.attr._id,
            requestedSubscriptions: [
                {
                    subscriptionType: this.attr.subTypeRoute,
                    destinationCode: this.attr.searchData.location2AirportCode,
                    originCode: this.attr.searchData.location1AirportCode
                }, {
                    subscriptionType: this.attr.subTypeHwdCity,
                    destinationCode: this.attr.searchData.location2AirportCode
                }, {
                    subscriptionType: this.attr.subTypeDeparture,
                    originCode: this.attr.searchData.location1AirportCode
                }, {
                    subscriptionType: this.attr.subTypeHwdUpdates
                }, {
                    subscriptionType: this.attr.subTypeAwdUpdates
                }, {
                    subscriptionType: this.attr.subTypeAirfareInsider
                }, {
                    subscriptionType: this.attr.subTypeTripWatch
                }, {
                    subscriptionType: this.attr.subTypeSpecialOffer
                }
            ]
        };
    };

    this.after("initialize", function () {
        this.on(this.select("_watchRouteSelector"), "click", this.showWatchDropdown);
        this.on(this.select("_closeSelector"), "click", this.hideWatchDropdown);
        this.on(this.select("_submitAlertElement"), "click", this.submitAlert);

        this.buildSubData();

    });
}

module.exports = WatchRouteUI;
