/**
 * This calendar ui component is meant to create a date range picker from a given input. Attach it to the container
 * for the input, and provide an _inputSelector attribute if there is more than one input in the container.
 *
 * It formats the date range on the input as MM/DD-MM/DD by default, but this can be changed by providing a
 * dateRangeFormat attribute, and a dateRangeSeparator attribute. When the datepicker is only for a single date, it uses
 * the singleDateFormat attribute to format the date.
 *
 * This component also looks out for radio button changes, such as a change from flight search to hotel search, or a
 * change from one way to round trip, and updates the input with the correct datepicker accordingly. It will use a single
 * date picker for one way trips, and a date range picker for all other flight and search types.
 *
 * Listens To:
 * =========
 *
 * @event ui:radio:change => a radio button has been changed
 *  @param {Object} searchData
 *      @param {String} name the name of the radio button that has been changed (flightType or searchType usually)
 *      @param {String} value the value of the newly selected radio button
 */

var doubleCalendar = require("./calendar");
var _ = require("underscore");
var moment = require("moment");
require("bootstrap-daterangepicker");

var component = doubleCalendar.mixin(calendar2);

function calendar2() {
    this.attributes({
        _inputSelector: "input",

        dateRangeFormat: "MM/DD",

        dateRangeSeparator: "-",

        oneway: false,

        hotel: true,

        singleDateFormat: "MM/DD/YYYY",

        extraOptions: {}
    });

    this.getDatePickerOptions = function (oneway) {
        var options = {
            startDate: this.attr.date1Moment,
            minDate: moment()
        };

        if (oneway) {
            _.extend(options, {
                singleDatePicker: true,
                locale: {
                    format: this.attr.singleDateFormat
                }
            });
        } else {
            _.extend(options, {
                autoApply: true,
                endDate: this.attr.date2Moment,
                dateLimit: {
                    days: this.attr.checkoutMaxDays
                },
                locale: {
                    format: this.attr.dateRangeFormat,
                    separator: this.attr.dateRangeSeparator
                }
            });
        }
        _.extend(options, this.attr.extraOptions);
        return options;
    };

    this.onDateSelected = function (start, end, label) {
        this.updateDateMoments(start, end);
    };

    this.updateDateMoments = function(checkinMoment, checkoutMoment, silent) {
        /* {moment} */
        this.attr.date1Moment = checkinMoment;
        this.attr.date2Moment = checkoutMoment;

        this.updateCalendars(this.attr.date1Moment, this.attr.date2Moment);
        if (!silent) {
            this.$node.trigger(this.attr.EV_CHANGED_DATES, {
                date1: this.attr.date1Moment.format(this.attr.DATE_FORMAT),
                date2: this.attr.date2Moment.format(this.attr.DATE_FORMAT)
            });
        }


    };

    this.setup = function (oneway) {
        this.select("_inputSelector")
            .daterangepicker(this.getDatePickerOptions(oneway), _.bind(this.onDateSelected, this));
        this.updateDataValue(this.attr.date1Moment, this.attr.date2Moment);
    };

    this.updateCalendars = function (date1Moment, date2Moment) {
        this.updateDataValue(date1Moment, date2Moment);
    };

    this.updateDataValue = function (date1Moment, date2Moment) {
        var input = this.select("_inputSelector");

        var date1String = date1Moment.clone().format(this.attr.DATE_FORMAT);
        var date2String = date2Moment.clone().format(this.attr.DATE_FORMAT);

        input.attr("data-date1", date1String);
        input.attr("data-date2", date2String);
        input.data("date1", date1String);
        input.data("date2", date2String);
        input.data("daterangepicker").setStartDate(date1Moment);
        if (!input.data("daterangepicker").singleDatePicker) {
            input.data("daterangepicker").setEndDate(date2Moment);
        }
    };

    this.after("initialize", function () {
        this.setup(this.attr.oneway);
        this.on(this.attr.EV_NEW_DATE_1, this.updateDate1External);
        this.on(this.attr.EV_NEW_DATE_2, this.updateDate2External);
    });

    this.after("updateDate1External updateDate2External", function () {
        this.updateDataValue(this.attr.date1Moment, this.attr.date2Moment);
    });

}

module.exports = component;