var airExactSearch = require("src/desktop/modules/flight-search-form/lib/ui/air-exact-date-sub-search-form");
var checkboxUpdate = require("src/desktop/modules/flight-search-form/lib/mixins/sub-widget-checkbox");
var win = require("win");

var component = airExactSearch.mixin(checkboxUpdate, function () {
    this.attributes({
        datesInputSelector: ".date_container input",
        emailCheckbox: ".sub-widget-email-checkbox #emailCheckbox",
        redirectOnSubmit: true
    });

    this.getData = function () {
        var formData = this.getFormData();
        // checks if it is a single field calendar and get the date values
        if (formData.date1 == null && formData.date2 == null && this.select("datesInputSelector").length) {
            var inputs = this.select("datesInputSelector");
            formData.date1 = inputs.data("date1");
            formData.date2 = inputs.data("date2");
        }
        return formData;
    };

    this.openFromLink = function (formData) {
        var airportCode = formData.location1.airportCode.code;
        if (formData.location1.airportCode.code === undefined) {
            airportCode = formData.location1.airportCode;
        }
        var params = {
            name: formData.location1.parentDisplayName.replace(/,/g, ""),
            code: airportCode
        };

        win.location.href = this.buildFromUrl(params);
        return true;
    };

    this.submit = function (event) {
        event.preventDefault();
        this.clearErrors();
        var formData = this.getData();

        if (!this.validateData(formData)) {
            return;
        }

        if (this.select("emailCheckbox").attr("checked")) {
            this.doSub(formData);
        }

        this.trigger(this.attr.EV_SEARCH_FORM_SUBMITTED);

        // open a new tab having tab-browsing / mobile lineup
        this.openTabBrowsing(formData);

        if (this.attr.redirectOnSubmit) {
            // redirect to fares-from page
            this.openFromLink(formData);
        }
    };

    this.externalSearchDataChanged = function (event, searchData) {
        var newValue;
        if (!searchData) {
            return;
        }

        if (searchData.searchDate1 && searchData.searchDate1.date) {
            this.select("datesInputSelector").trigger(this.attr.EV_EXTERNAL_DATE_ONE_CHANGED, searchData.searchDate1.date);
        }

        if (searchData.searchDate2 && searchData.searchDate2.date) {
            this.select("datesInputSelector").trigger(this.attr.EV_EXTERNAL_DATE_TWO_CHANGED, searchData.searchDate2.date);
        }

        if (searchData.searchType !== "air") {
            return;
        }

        if (searchData.location1AirportId) {
            var location1Input = this.select("_location1Selector");
            newValue = {
                id: searchData.location1AirportId,
                airportCode: searchData.location1AirportCode,
                parentId: searchData.location1CityId,
                parentDisplayName: searchData.location1CityDisplayName,
                departureFareEligible: searchData.location1DepartureFareEligible
            };
            location1Input.data("value", newValue);
            location1Input.val(searchData.location1Name);
        }

        if (searchData.location2AirportId) {
            var location2Input = this.select("_location2Selector");
            newValue = {
                id: searchData.location2AirportId,
                airportCode: searchData.location2AirportCode,
                parentId: searchData.location2CityId,
                parentDisplayName: searchData.location2CityDisplayName,
                departureFareEligible: searchData.location2DepartureFareEligible
            };
            location2Input.data("value", newValue);
            location2Input.val(searchData.location2Name);
        }

        if (searchData.flightSearchType) {
            this.select("_flightTypeSelector").trigger(this.attr.EV_EXTERNAL_RADIO_CHANGED, {
                name: "flightType",
                value: searchData.flightSearchType
            });
            if (searchData.flightSearchType === "oneway") {
                this.select("_date2Selector").hide();
                this.$node.removeClass("roundtrip");
            } else {
                this.select("_date2Selector").show();
                this.$node.removeClass("oneway");
            }
            this.$node.addClass(searchData.flightSearchType);
        }

        if (searchData.travelers) {
            this.select("_numTravelersSelector").trigger(this.attr.EV_EXTERNAL_SELECTBOX_CHANGED, {
                name: "numTravelers",
                value: searchData.travelers
            });
        }

        this.trigger(this.attr.EV_SEARCH_DATA_AFTER_UPDATE, { id: searchData.id });
    };
});

module.exports = component;